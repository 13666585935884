// extracted by mini-css-extract-plugin
export var active = "collaborations-carousel-screen-module--active--6054a";
export var background = "collaborations-carousel-screen-module--background--d1ec9";
export var button = "collaborations-carousel-screen-module--button--b7443";
export var buttonActive = "collaborations-carousel-screen-module--button-active--c19eb";
export var buttonDisabled = "collaborations-carousel-screen-module--button-disabled--ac4d8";
export var deco = "collaborations-carousel-screen-module--deco--a047c";
export var grid = "collaborations-carousel-screen-module--grid--91e68";
export var image = "collaborations-carousel-screen-module--image--1138d";
export var imageCenter = "collaborations-carousel-screen-module--image-center--ef267";
export var next = "collaborations-carousel-screen-module--next--f7491";
export var prev = "collaborations-carousel-screen-module--prev--277c4";
export var section = "collaborations-carousel-screen-module--section--147e5";
export var slider = "collaborations-carousel-screen-module--slider--60a8d";
export var sliderNav = "collaborations-carousel-screen-module--slider-nav--c85a5";
export var sliderNavigation = "collaborations-carousel-screen-module--slider-navigation--f2111";
export var sliderNavigationContainer = "collaborations-carousel-screen-module--slider-navigation-container--dbd24";
export var text = "collaborations-carousel-screen-module--text--b6cc5";
export var title = "collaborations-carousel-screen-module--title--8f4d6";