// extracted by mini-css-extract-plugin
export var active = "team-screen-module--active--37ae6";
export var button = "team-screen-module--button--a8b8c";
export var container = "team-screen-module--container--8f9ec";
export var grid = "team-screen-module--grid--5da11";
export var next = "team-screen-module--next--26d71";
export var prev = "team-screen-module--prev--a0e79";
export var section = "team-screen-module--section--41571";
export var slider = "team-screen-module--slider--38435";
export var sliderButton = "team-screen-module--slider-button--a54f2";
export var sliderButtonContainer = "team-screen-module--slider-button-container--858b5";
export var sliderNav = "team-screen-module--slider-nav--3208f";
export var swiper = "team-screen-module--swiper--b3c02";
export var text = "team-screen-module--text--de820";